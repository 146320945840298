import React from 'react'
import Video from '../components/video'

export default function PortraitVideo ({heading, asset, description}) {

  return (

    <section className="portrait-video">
      <h1 className="portrait-video__heading">
        {heading}
      </h1>
      <div className="portrait-video__video">
        <Video assetDocument={asset}/>
      </div>
      <div className="portrait-video__description">
        {description}
      </div>
    </section>
  )
}
