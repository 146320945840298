import React from 'react'
import Showcase from './showcase'
import classnames from 'classnames'

export default function ShowcaseRow ({heading, horizontal, smallerImages, showcases = []}) {

  const className = classnames({
    'showcase-row': true,
    'showcase-row--horizontal': horizontal,
    'showcase-row--smaller-images': smallerImages
  })

  return (
    <section className={className}>
      {heading && (
        <h1 className="heading">{heading}</h1>
      )}
      <div className="showcase-row__grid">
        {showcases.map((showcase, i) => (
          <Showcase key={i} {...showcase} />
        ))}
      </div>
    </section>
  )
}
