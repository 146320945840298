import React, {useState} from 'react'
import {LocaleLink, localiseMessage} from '../helpers/locale'
import Video from '../components/video'
import {useCarrousel} from '../helpers/hooks'
import classnames from 'classnames'
import {LEARN_MORE} from '../helpers/messages'

export default function PortraitCarrousel ({videos, title, description, linkUrl, linkText, interval}) {

  const [{isReady, classModifiers, direction}, {goToIndex, nextItem, prevItem}] = useCarrousel(videos.length, {interval})
  const [videoIsPaused, setVideoIsPaused] = useState(false)

  const pauseButtonClicked = () => setVideoIsPaused(prevState => !prevState)

  const className = classnames({
    'portrait-carrousel': true,
    'portrait-carrousel--ready': isReady,
    'portrait-carrousel--paused': videoIsPaused,
    'portrait-carrousel--backwards': direction == 'backwards'
  })

  return (
    <div className={className}>
      <div className="portrait-carrousel__inner">
        <div className="portrait-carrousel__text">
          <div className="portrait-carrousel__text">
            <h2>{title}</h2>
            {description}
            {linkUrl && <LocaleLink className="button" to={linkUrl}>{linkText || localiseMessage(LEARN_MORE)}</LocaleLink>}
          </div>
        </div>
        <div className="portrait-carrousel__items">
          {videos.map((video, i) => (
            <div key={i} className={`portrait-carrousel__item portrait-carrousel__item${classModifiers[i]}`} onClick={() => goToIndex(i)}>
              <Video assetDocument={video} isPaused={videoIsPaused}/>
            </div>
          ))}
        </div>
        <div className="portrait-carrousel__direction-controls">
          <div className="portrait-carrousel__left-button" onClick={() => prevItem(true)}></div>
          <div className="portrait-carrousel__pause-button" onClick={pauseButtonClicked}></div>
          <div className="portrait-carrousel__right-button" onClick={() => nextItem(true)}></div>
        </div>
      </div>
    </div>
  )
}
