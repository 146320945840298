import React from 'react'
import classnames from 'classnames'

export default function StatsText ({heading, stats}) {

  const classsName = classnames({
    'stats-text': true
  })

  return (

    <section className={classsName}>
      <h1 className="stats-text__heading">
        {heading}
      </h1>
      <div className="stats-text__stats">
        {stats.map((stat, i) => (
          <React.Fragment key={i}>
            <h2>{stat.amount}</h2>
            <p>{stat.postfix}</p>
          </React.Fragment>
        ))}
      </div>
    </section>
  )
}

/* Stat objects should consist of {amount, postfix} */
