import React from 'react'

export default function InformationText ({heading, subheading, quote}) {

  return (

    <section className="quote-text">
      <div className="quote-text__heading">
        <h1>{heading}</h1>
        <h2>{subheading}</h2>
      </div>
      <div className="quote-text__quote">
        {quote}
      </div>
    </section>
  )
}
