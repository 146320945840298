import React from 'react'
import Img from 'gatsby-image'

export default function LargeImage ({heading, fluidImage}) {

  return (

    <section className="large-image">
      <div className="large-image__heading">{heading}</div>
      <Img className="large-image__image" fluid={fluidImage}/>
    </section>
  )
}
