import React from 'react'
import InformationText from '../components/information-text'
import LargeImage from '../components/large-image'
import ShowWhenScrolled from '../components/show-when-scrolled'
import FullWidthImage from '../components/full-width-image'
import QuoteText from '../components/quote-text'
import LandscapeVideo from '../components/landscape-video'
import StatsText from '../components/stats-text'
import PortraitVideo from '../components/portrait-video'
import Strapline from '../components/strapline'
import ShowcaseRow from '../components/showcase-row'
import PortraitCarrousel from '../components/portrait-carrousel'
import PortraitImage from '../components/portrait-image'
import ClientJourney from '../components/client-journey'
import CasestudyCarrousel from '../components/casestudy-carrousel'
import {localiseObject} from '../helpers/locale'
import {unblockPortableText, urlFor} from '../helpers/sanity'
import {PAGE_URLS} from '../helpers/constants'

export default function Content ({content}) {

  const {_type} = content

  switch (_type) {

    case 'textBlock': {

      const {body, caption, bold} = content

      return (
        <ShowWhenScrolled>
          <InformationText
            important={bold}
            heading={localiseObject(caption)}
            description={unblockPortableText(localiseObject(body))}
          />
        </ShowWhenScrolled>
      )
    }

    case 'largeImage': {

      const {asset: {fluid}} = content

      return <LargeImage fluidImage={fluid}/>

    }

    case 'imageDivider': {

      const {asset} = content

      return <FullWidthImage imageUrl={urlFor(asset)} />

    }

    case 'quote': {

      const {company, jobTitle, name, quote} = content

      return (
        <ShowWhenScrolled>
          <QuoteText
            heading={name}
            subheading={`${localiseObject(jobTitle)}, ${company}`}
            quote={unblockPortableText(localiseObject(quote))}
          />
        </ShowWhenScrolled>
      )
    }

    case 'landscapeVideo': {

      const {video: {asset}} = content

      return (
        <ShowWhenScrolled>
          <LandscapeVideo asset={asset} />
        </ShowWhenScrolled>
      )
    }

    case 'keyFigures': {

      const {caption, figures} = content

      const stats = figures.map(({unit, value, modifier, postfix}) => ({
        amount: `${unit || ''}${value || ''}${modifier || ''}`,
        postfix: localiseObject(postfix)
      }))

      return (
        <ShowWhenScrolled>
          <StatsText
            heading={localiseObject(caption)}
            stats={stats}
          />
        </ShowWhenScrolled>
      )
    }

    case 'portraitVideo': {

      const {body, video: {asset}, caption} = content

      return (
        <ShowWhenScrolled>
          <PortraitVideo
            heading={localiseObject(caption)}
            description={unblockPortableText(localiseObject(body))}
            asset={asset}
          />
        </ShowWhenScrolled>
      )
    }

    case 'centerText': {

      const {body, caption} = content

      return (
        <ShowWhenScrolled>
          <Strapline
            heading={localiseObject(caption)}
            text={unblockPortableText(localiseObject(body))}
          />
        </ShowWhenScrolled>
      )
    }

    case 'iconShowcase': {

      const {item, caption} = content

      const showcase = {

        heading: localiseObject(caption),
        horizontal: true,
        smallerImages: true,
        showcases: item.map(item => {

          const {image: {asset}, title, body} = item

          return {
            image: urlFor(asset),
            title: localiseObject(title),
            textCentred: true,
            description: unblockPortableText(localiseObject(body))
          }
        })
      }

      return (
        <ShowWhenScrolled>
          <ShowcaseRow {...showcase} />
        </ShowWhenScrolled>
      )
    }

    case 'portraitVideos': {

      const {caption, body, video} = content

      const portraitCarrousel = {
        title: localiseObject(caption),
        description: unblockPortableText(localiseObject(body)),
        linkText: '',
        linkUrl: '',
        videos: video.map(video => video.asset)
      }

      return (
        <ShowWhenScrolled>
          <PortraitCarrousel {...portraitCarrousel} />
        </ShowWhenScrolled>
      )
    }

    case 'portraitImage': {

      const {caption, body, image} = content

      const portraitImage = {
        title: localiseObject(caption),
        description: unblockPortableText(localiseObject(body)),
        linkText: '',
        linkUrl: '',
        imageUrl: urlFor(image.asset)
      }

      return (
        <ShowWhenScrolled>
          <PortraitImage {...portraitImage} />
        </ShowWhenScrolled>
      )
    }

    case 'process': {

      const {caption, steps} = content

      const process = {
        heading: localiseObject(caption),
        journeys: steps.map(step => {

          const {title, body} = step

          return {
            title: localiseObject(title),
            description: unblockPortableText(localiseObject(body))
          }
        })
      }

      return (
        <ShowWhenScrolled>
          <ClientJourney {...process} />
        </ShowWhenScrolled>
      )
    }

    case 'casestudyList': {

      const {caption, caseStudies} = content

      const config = {
        heading: localiseObject(caption),
        casestudies: caseStudies.map(caseStudy => {

          const {logo, reference} = caseStudy
          const {client, referenceImage: {asset: imageAsset}, intro, slug: {current}} = reference
          const {asset: logoAsset} = logo

          return {
            logoUrl: urlFor(logoAsset),
            imageUrl: urlFor(imageAsset),
            title: client,
            description: unblockPortableText(localiseObject(intro)),
            url: `/${PAGE_URLS.CASESTUDIES}/${current}`
          }
        })
      }

      return (
        <ShowWhenScrolled>
          <CasestudyCarrousel {...config} />
        </ShowWhenScrolled>
      )
    }

    default:
      return null
  }
}
