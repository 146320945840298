import React from 'react'
import {LocaleLink, localiseMessage} from '../helpers/locale'
import classnames from 'classnames'
import {LEARN_MORE} from '../helpers/messages'

export default function Showcase ({image, title, description, url, textCentred}) {

  const className = classnames({
    'showcase': true,
    'showcase--text-centred': textCentred
  })

  return (
    <section className={className}>
      <div className="showcase__image-wrapper">
        <div className="showcase__image" style={{backgroundImage: `url(${image})`}}></div>
      </div>
      <div className="showcase__text">
        <h2>{title}</h2>
        {description}
        {url && <LocaleLink className="button" to={url}>{localiseMessage(LEARN_MORE)}</LocaleLink>}
      </div>
    </section>
  )
}
