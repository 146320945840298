import React from 'react'
import Video from '../components/video'

export default function LandscapeVideo ({heading, asset}) {

  return (

    <section className="landscape-video">
      <h1 className="landscape-video__heading">
        {heading}
      </h1>
      <div className="landscape-video__video">
        <Video
          assetDocument={asset}
          controls={true}
        />
      </div>
    </section>
  )
}
