import React, {useRef, useEffect} from 'react'
import ReactPlayer from 'react-player'

export default function Video ({assetDocument, controls, muted = true, autoPlay = true, loop = true, isPaused = false}) {

  const ref = useRef(null)

  // temporary fix for mobiles where we can show a poster image instead of video
  useEffect(() => {

    if (ref.current && ref.current.wrapper)
      ref.current.wrapper.querySelector('video').setAttribute('poster', `https://image.mux.com/${assetDocument.playbackId}/thumbnail.jpg`)

  }, [])

  return (

    <ReactPlayer
      url={"https://stream.mux.com/" + assetDocument.playbackId + ".m3u8"}
      playing={!!autoPlay && !isPaused}
      loop={!!loop}
      controls={!!controls}
      muted={!!muted}
      width="null"
      height="null"
      ref={ref}
    />
  )
}
