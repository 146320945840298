import React from 'react'
import {LocaleLink, localiseMessage} from '../helpers/locale'
import {LEARN_MORE} from '../helpers/messages'

export default function PortraitImage ({imageUrl, title, description, linkUrl, linkText}) {

  return (
    <div className="portrait-image">
      <div className="portrait-image__inner">
        <div className="portrait-image__text">
          <div className="portrait-image__text">
            <h2>{title}</h2>
            {description}
            {linkUrl && <LocaleLink className="button" to={linkUrl}>{linkText || localiseMessage(LEARN_MORE)}</LocaleLink>}
          </div>
        </div>
        <div className="portrait-image__image" style={{backgroundImage: `url(${imageUrl})`}}></div>
      </div>
    </div>
  )
}
