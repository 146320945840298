import React from 'react'
import classnames from 'classnames'

export default function InformationText ({heading, description, important}) {

  const classsName = classnames({
    'information-text': true,
    'information-text--important': important
  })

  if (typeof description == 'string')
    description = (<>{description}</>)

  return (
    <section className={classsName}>
      <h1 className="information-text__heading">
        {heading}
      </h1>
      <div className="information-text__description">
        {description}
      </div>
    </section>
  )
}
