import React, {useState} from 'react'
import {LocaleLink, localiseMessage} from '../helpers/locale'
import classnames from 'classnames'
import {LEARN_MORE} from '../helpers/messages'

export default function CasestudyCarrousel ({heading, casestudies = []}) {

  const [state, setState] = useState({index: 0, left: 0, right: 1, orientation: null})

  const controlClicked = index => {

    setState(prevState => {

      return getNextStateFromIndex(prevState, index)

    })
  }

  const getNextStateFromIndex = (prevState, index) => {

    if (isNaN(index))
      index = prevState.index + 1

    const orientation = prevState.orientation == 'right' ? 'left' : 'right'

    let {left, right} = prevState

    const nextIndex = index % casestudies.length

    if (orientation == 'right' && nextIndex != right) {

      right = nextIndex

    } else if (orientation == 'left' && nextIndex != left) {

      left = nextIndex

    }

    return {index: nextIndex, orientation, left, right}

  }

  const getControlClassName = index => {

    return classnames({
      'casestudy-carrousel__control': true,
      'casestudy-carrousel__control--current': state.index == index
    })
  }

  const leftStudy = casestudies[state.left]
  const rightStudy = casestudies[state.right]
  const imageStyle = {backgroundImage: `url(${leftStudy.imageUrl}), url(${rightStudy.imageUrl})`}

  const className = classnames({
    'casestudy-carrousel': true,
    'casestudy-carrousel--right': state.orientation == 'right',
    'casestudy-carrousel--left': state.orientation == 'left'
  })

  return (
    <section className={className}>
      {heading && <h1 className="heading">{heading}</h1>}
      <div className="casestudy-carrousel__controls">
        {casestudies.map((casestudy, i) => (
          <i key={i} className={getControlClassName(i)} onClick={() => controlClicked(i)}>
            <img alt={casestudy.title} src={casestudy.logoUrl} />
          </i>
        ))}
      </div>
      <div className="casestudy-carrousel__inner">
        <div className="casestudy-carrousel__image" style={imageStyle}></div>
        <div className="casestudy-carrousel__text">
          <div className="casestudy-carrousel__text-left">
            <h2>{leftStudy.title}</h2>
            {leftStudy.description}
            {leftStudy.url && <LocaleLink className="button" to={leftStudy.url}>{localiseMessage(LEARN_MORE)}</LocaleLink>}
          </div>
          <div className="casestudy-carrousel__text-right">
            <h2>{rightStudy.title}</h2>
            {rightStudy.description}
            {rightStudy.url && <LocaleLink className="button" to={rightStudy.url}>{localiseMessage(LEARN_MORE)}</LocaleLink>}
          </div>
        </div>
      </div>
    </section>
  )
}
