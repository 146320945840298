import React, {useRef} from 'react'
import VisibilitySensor from 'react-visibility-sensor'

export default function ShowWhenScrolled ({children}) {

  const ref = useRef(null)

  const visibilityChanged = isVisible => {

    if (isVisible && ref.current)
      ref.current.classList.add('show-when-scrolled--scrolled')

  }

  return (
    <VisibilitySensor onChange={visibilityChanged} minTopValue={50} partialVisibility={true}>
      <div className="show-when-scrolled" ref={ref}>
        {children}
      </div>
    </VisibilitySensor>
  )
}
