import React from 'react'

export default function ClientJourney ({heading, journeys = []}) {

  return (
    <section className="client-journey">
      {heading && (
        <h1 className="heading">{heading}</h1>
      )}
      <div className="client-journey__background">
        {journeys.map((journey, i) => (
          <div key={i} className="client-journey__background-step"></div>
        ))}
      </div>
      <div className="client-journey__grid">
        {journeys.map(({title, description}, i) => (
          <div key={i} className="client-journey__journey">
            <h2>{title}</h2>
            {description}
          </div>
        ))}
      </div>
    </section>
  )
}
